import { RouteObject } from "react-router-dom";
import UnderConstruction from "../pages/underConstruction";
import Layout from "../components/layout";

const routes: RouteObject[] = [
    { 
        path: "/", 
        element: <Layout />,
        children: [
            __filename.endsWith(".js") ? {
                id: "Home",
                path: "",
                element: <UnderConstruction />
            } : {
                id: "Home",
                path: "",
                element: <UnderConstruction />
            },
            {
                id: "About",
                path: "/about",
                element: <>About us</>
            }
        ]
    },
]

export default routes;