import { Link, useLocation } from "react-router-dom";
import NavbarItem from "./navbarItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../config/store";
import { AppBar, Box, Button, Container, FormControl, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import { setLanguage, Translations } from "../../config/translationSlice";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useState, useRef } from "react";

export default function Navbar() {
    const routes = useSelector((state: RootState) => state.pagination);
    const { pathname } = useLocation();
    const [localState, setLocalState] = useState<{ isOpened: boolean }>({ isOpened: false });
    const dispatch = useDispatch();

    const currentRoutes = routes.find(routeSet => routeSet.path === pathname)?.children ?? [];
    const toggleMenu =() => setLocalState({...localState, isOpened: !localState.isOpened})

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Mobile Menu Button */}
                    {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton 
                            color="inherit"
                            onClick={toggleMenu} // Open the menu
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box> */}

                    {/* Desktop Menu */}
                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {currentRoutes.map(v => (
                            <NavbarItem url={`${v.path}`} label={`${v.id}`} key={v.id} />
                        ))}
                    </Box> */}

                    {/* Language Selector */}
                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                        <FormControl sx={{ m: 1, minWidth: 60 }}>
                            <Select
                                onChange={(ev) => dispatch(setLanguage(ev.target.value as Translations))}
                                value={useSelector((state: RootState) => state.translation.language)}
                                color="secondary"
                                sx={{
                                    color: 'white',
                                    border: 0
                                }}
                            >
                                <MenuItem value="BE">{"\u{1F1E7}\u{1F1EA}"} BE</MenuItem>
                                <MenuItem value="EN">{"\u{1F1FA}\u{1F1F8}"} EN</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Toolbar>
            </Container>

            {/* Mobile Menu List */}
            {/* <Box
                component="div"
                sx={{
                    display: {
                        xs: localState.isOpened ? "flex" : "none",
                        md: "none"
                    },
                    flexDirection: "column"
                }}
            >
                {currentRoutes.map(v => (<NavbarItem 
                    url={`${v.path}`} 
                    label={`${v.id}`} 
                    key={v.id} 
                />
                ))}
            </Box> */}

        </AppBar>
    );
}
