import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import I18Code from "../services/i18/i18code";
import i18next from "i18next";

export type Translations = "BE" | "EN"
export type TranslationState = { 
    language: Translations,
    translations:  {
        [key in Translations]: {
            translation: {
                [key in I18Code]: string;
            }
        };
    }
}

const initialState: TranslationState = { 
    language: "BE",
    translations: 
    {
        EN: {
            translation: {
                [I18Code.UNDER_CONSTRUCTION_TITLE]:  "Under construction",
                [I18Code.UNDER_CONSTRUCTION_MESSAGE]: "This website is currently under development.\nIf you have any questions, please refer to the following email address: ",
            },
        },
        BE: {
            translation: {
              [I18Code.UNDER_CONSTRUCTION_TITLE]: "Tijdelijk onbeschikbaar",
              [I18Code.UNDER_CONSTRUCTION_MESSAGE]:  "Deze website is momenteel in ontwikkeling.\nAls u vragen heeft, kunt u het volgende e-mailadres raadplegen: ",
            },
        },
    }
}

i18next.init({
    lng: initialState.language,
    debug: true,
    resources: {
      ... initialState.translations
    },
});

const translationSlice = createSlice({
    name: "TRANSLATIONS",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<Translations>) => {
            state.language = action.payload;
            i18next.changeLanguage(action.payload)
        }
    }
})

export default translationSlice.reducer;
export const {setLanguage} = translationSlice.actions;