import { configureStore } from "@reduxjs/toolkit";
import paginationReducer from "./paginationSlice"
import translationReducer from "./translationSlice"

const store = configureStore({
    reducer: {
        pagination: paginationReducer,
        translation: translationReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store; 