import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { RootState } from "../config/store";




export default function Router(){
   const routes = useSelector((state: RootState) => state.pagination);

  return (<>
    <RouterProvider router={createBrowserRouter(routes)} />
  </>)
};