import { createSlice } from "@reduxjs/toolkit";
import { RouteObject } from "react-router-dom";
import routes from "./routes";

type PaginationState = RouteObject[];

const initialState: PaginationState =  routes;

const paginationSlice = createSlice({
    name: "PAGINATION",
    initialState: initialState,
    reducers: {}
})

export const {} = paginationSlice.actions;
export default paginationSlice.reducer;