
import { useEffect, useState } from 'react';
import styles from "./underConstruction.module.css";
import I18Code from '../services/i18/i18code';
import { useSelector } from 'react-redux';
import { RootState } from '../config/store';
import { useTranslation } from 'react-i18next';

export default function UnderConstruction () {
    const {t} = useTranslation();

    useEffect(() => {
      document.title = t(I18Code.UNDER_CONSTRUCTION_TITLE)
    }, [useSelector((state: RootState) => state.translation.language)]);
  
    const messageLines = `${t(I18Code.UNDER_CONSTRUCTION_MESSAGE)}`.split("\n")
    return (
      <div className={styles.main}>
        <h1 className={styles["text-color--gray"]}>{t(I18Code.UNDER_CONSTRUCTION_TITLE)}</h1>
        <p className={styles["text-align--center"]}>
          {messageLines.map((str, index) => {
            return <>{str}{index !== messageLines.length-1 ? <br /> : <></>}</>
          })} 
          <a href='mailto:contact@oste.dev'>contact@oste.dev</a>
        </p>
      </div>
    );
  
}